:root {
  /* --active-color: rgb(0, 100, 210); */
  --active-color: -webkit-linear-gradient(left, #685ecb, #f08c96);
  --br: 5px;
  --text-sec-color: rgb(115, 115, 115);
  --hover-trans: 250ms ease 0s, background-color 250ms ease 0s;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
