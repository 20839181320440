.conv_cont {
  position: relative;
}

.conv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: var(--hover-trans);
}

.minimize-btn {
  align-items: center;
  /* background: none; */
  border: none;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 30px;
  min-width: 35px;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 10px;
  transition: var(--hover-trans);
  z-index: 99999999;
}
.minimize-btn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.conv_list {
  /* margin-top: 53px; */
  overflow: auto;
  height: calc(100vh - 80px);
}

.agents_name {
  display: flex;
}
.conv_list > div:last-child {
  margin-bottom: 75px;
}

.conv_cont .header_title {
  /* position: absolute; */
  top: 0;
  width: 100%;
  background: var(--active-color);
  padding: 1rem 0;
  text-align: center;
  color: white;
}

.conv_cont .header_title h1 {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
}

.short_msg {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 1rem;
  width: 0; /* Added to allow text-overflow to work properly */
}

.short_msg .last_msg {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.short_msg span {
  font-size: 14px;
}
.short_msg .info {
  color: rgb(102, 102, 102);
}

.conv:hover {
  cursor: pointer;
  background-color: rgba(0, 87, 255, 0.1);
}

.icon {
  flex-shrink: 0;
  color: var(--active-color);
  font-size: 12px;
}

.avatar {
  flex-shrink: 0;
  font-size: 14px;
}

.conv_cont .send_us_btn {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
}

.send_us_btn button {
  font-size: 14px;
  padding: 10px 16px;
  font-weight: 500;
  color: white;
  background: var(--active-color);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.send_us_btn button:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.send_us_btn button .fa-paper-plane {
  margin-left: 8px;
}

.conv_list .empty {
  margin: 0 !important;
  height: 100%;
}

.newMsgBadge {
  width: 100%;
}

.newMsgBadge .ant-badge-count {
  transform: translate(0, 0);
}
