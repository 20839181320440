.headerWidget {
  /* background: var(--active-color); */
  padding: 0.7rem 0.7rem;
  color: white;
  position: fixed;
  width: calc(100% - 22px);
  z-index: 9999;
  font-family: "Open Sans", sans-serif;
}

.header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons-area i {
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}
.icons-area .fa-close {
  margin-left: 15px;
}
.msg-icon .fa-solid {
  font-size: 1.4rem;
  color: #fff;
}
.msg-icon {
  position: relative;
  transition: var(--hover-trans);
  color: rgb(255, 255, 255);
  min-width: 48px;
  min-height: 30px;
  background: none;
  border: none;
  border-radius: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  /* padding: 12px; */
}

.msg-icon:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.msg-icon .fa-chevron-left {
  font-size: 12px;
}

.header_btns {
  padding: 0 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.initials {
  display: flex;
  flex: 1;
  margin-left: 5px;
}

.ant-popover-arrow {
  margin-top: 1px !important;
}
