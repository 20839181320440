.center {
  text-align: center !important;
}

.center_items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.flex_items {
  display: flex;
  align-items: center;
}
