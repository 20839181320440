.sender_msg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.sender_msg_row {
  max-width: 70%;
  padding: 0 1rem;
  border-radius: 10px 10px 0 10px;
}

.sender_msg_row_audio {
  max-width: 70%;
  padding: 0 1rem;
  border-radius: 10px 10px 0 10px;
}
.msg_div {
  font-size: 14px;
  word-wrap: break-word;
}

.timestamp_div {
  font-size: 14px;
  text-align: end;
}

.timestamp_div p {
  margin-top: 2px;
}

.sender_msg_row_audio audio {
  max-width: 100%;
}
