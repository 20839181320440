.receiver_msg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.receiver_msg_row {
  /* white-space: pre-wrap; */
  max-width: 70%;
  padding: 0 1rem;
  border-radius: 10px 10px 10px 0;
}

.msg_div {
  font-size: 14px;
  word-wrap: break-word;
}

.timestamp_div {
  font-size: 12px;
}
