.chat-ended-box {
  text-align: center;
}

.chat-ended-box p {
  margin: 0;
  font-weight: 500;
  color: #909090;
  font-size: 12px;
}

.chat-ended-box .link {
  color: #6182b8;
  cursor: pointer;
}

.send-box .emoji-box aside.emoji-picker-react {
  position: absolute;
  bottom: 35px;
  left: 10px;
}
.visitor-text-area {
  padding: 0px 0px 5px 10px;
  background: transparent !important;
}

.visitor-text-area:focus {
  border: 0 !important;
  outline: none;
  box-shadow: none;
}
.area-2-btns {
  margin-left: 5px;
  margin-right: 5px;
}
.visitor-text-area {
  border: 0 !important;
  outline: none;
}

.send-box {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 25px;
}

.recording {
  width: 220px !important;
  margin-right: 5px !important;
}

.recording .audio-recorder-mic {
  display: none !important;
}

.recording .audio-recorder-visualizer {
  width: 100px !important;
}
.audio-recorder-visualizer canvas {
  width: 100px !important;
}

/* .visitor-text-area:focus-visible{
outline: 1px solid gainsboro;
}
.visitor-text-area{
    border-radius: 3px;
    padding: 12px 5px 2px;
    border: 0;
    width: 100%;
    resize: none;
    height: 45px;

} */
