.main-sec {
  padding: 40px 0 0 0;
  z-index: 9999999999 !important;
}

.msg-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--active-color);
  width: 240px;
  padding: 10px 20px;
  margin: 0 0 8px 0;
  height: auto;
  font-family: "Open Sans", sans-serif;
  position: relative;
  border-radius: 10px;
}

.msg-area p {
  /* font-size: 12px; */
  /* font-weight: 600; */
  color: white !important;
  line-break: anywhere;
}

.msg-area p,
.msg-area p a {
  font-size: 13px;
  margin: 0;
  /* width: 190px; */
  font-weight: 400;
  color: #455a64;
}

.msg-area p.time {
  font-weight: bold;
  color: #000;
  text-align: end;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* font-size: 10px; */
  /* position: absolute; */
  /* width: unset !important; */
  /* top: 7px; */
  /* right: 8px; */
}

.msg-area p.time .fa-solid {
  font-size: 15px;
  margin-left: 5px;
}

.msg-area p a {
  color: #fff;
}

.receiver-msg-area p a {
  color: #6182b8;
}

.sending-area {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.time-content p {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.empty-div {
  height: 10px;
}

.reciever-area {
  align-items: center;
  padding-top: 10px;
}

.reciever-area .msg-area {
  background-color: rgba(242, 242, 242) !important;

  margin-bottom: 0;
}

.reciever-area .msg-area p {
  color: black !important;
}

.scroll {
  background: white;
  /* padding: 15px 0; */
  margin-top: 22px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.scroll .chat_area_row {
  padding: 5px 10px;
}

.send-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 20px);
  left: 0;
  background: #fff;
  z-index: 999999;
  border-top: 1px solid #efefef;
  padding: 12px 10px;
}

.send-box input {
  width: 100%;
  height: 39px;
  font-size: 14px;
  font-weight: 300;
  outline: none;
  margin-right: 6px;
  border: transparent;
}

.send-icons {
  width: 100%;
}

.send-icons .fa-solid {
  color: #aeaeae;
  font-size: 1rem;
  /* margin-right: 7px; */
}

.send-icons .fa-solid.fa-paper-plane {
  transform: rotate(58deg);
}

.emoji-btn .fas.fa-smile {
  color: rgb(255 195 57);
  /* margin-right: 7px; */
  /* margin-top: 5px; */
  font-size: 1rem;
}

.file-input {
  margin: 0 10px 0 5px;
}
.file-input input {
  display: none;
}

.emoji-btn {
  background-color: transparent;
  border: transparent;
  width: 29px;
  padding: 0;
}

.aside.emoji-picker-react {
  position: absolute !important;
  bottom: 34px;
}

.main-sec .container {
  max-width: 1400px;
  width: 100%;
}

.area-2 {
  margin-left: 5px;
  justify-content: end;
}

.area-1 {
  flex: 1;
}

.join-para {
  font-size: 12px;
  color: #909090;
  margin: 3px 0;
}

.join-para span {
  padding-left: 9px;
}

.reciever-area h4 {
  font-size: 12px;
  font-weight: 600;
  color: #6182b8;
  margin: 0;
  margin-bottom: 2px;
}

.times-now p {
  font-size: 11px;
  text-align: end;
  color: #000;
  font-weight: bold;
  /* position: absolute; */
  /* width: unset !important; */
  /* top: 7px; */
  /* right: 8px; */
}
.times-now {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

@media (max-width: 767px) {
  .send-box input {
    width: 230px;
  }

  /* .scroll {
        height: calc(100vh - 127px) !important;
    } */
}

/* *::-webkit-scrollbar {
    width: 10px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--dark-blue) var(--custom-white);
}

*::-webkit-scrollbar-track {
    background-color: var(--custom-white);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--dark-blue);
    border-radius: 20px;
    border: 3px solid var(--custom-white);
} */

.pf-area {
  width: 30px;
  margin-right: 6px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6182b8;
  border-radius: 100%;
}

.send_us_btn {
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
}

.send_us_btn button {
  font-size: 14px;
  padding: 8px 10px;
  font-weight: 500;
  color: white;
  background-color: var(--active-color);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.send_us_btn button:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.send_us_btn button .fa-paper-plane {
  margin-left: 8px;
}
